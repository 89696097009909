import { updateTimers, updateStickyText } from "../timer/timer";

const updateRound = (state) => {
  const timer = document.getElementById("countdown");
  timer.dataset.paused = state.paused === "true";
  timer.dataset.endTime = state.end_time;
  timer.dataset.pausedTimestamp = state.paused_timestamp;

  const paused = timer.dataset.paused === "true";

  const pauseButton = document.getElementById("pause");
  const resumeButton = document.getElementById("resume");
  if (timer.classList.contains("loading")) timer.classList.remove("loading");
  if (pauseButton) pauseButton.disabled = false;

  if (paused) {
    timer.classList.add("timer-paused");
    if (pauseButton) pauseButton.style.display = "none";
    if (resumeButton) resumeButton.style.display = "";
  } else {
    timer.classList.remove("timer-paused");
    if (resumeButton) resumeButton.style.display = "none";
    if (pauseButton) pauseButton.style.display = "";
  }
  updateTimers();
  updateStickyText();
};

export default updateRound;
