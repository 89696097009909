const updateStatusText = ({ leader, counts, allReady }) => {
  const gameStatus = document.querySelector("#game-status");
  const notReadyCount = counts.players - counts.ready;
  let text = "";
  let icon = "fa-exclamation-circle";
  let color = "#808080";

  if (!leader.id) {
    text = "Leader not here";
    return (gameStatus.innerHTML = generateHTML({ text, icon }));
  }
  if (allReady) {
    text = `Start the game, ${leader.name}`;
    color = "#00aa5a";
    icon = "fa-check";
  } else {
    text = `${notReadyCount} player${notReadyCount !== 1 ? "s" : ""} not ready`;
  }

  gameStatus.innerHTML = generateHTML({ text, icon });
  gameStatus.style.color = color;
};
const generateHTML = ({ text, icon }) => {
  return `<i class="fas ${icon}"></i>${" "}${text}`;
};

export default updateStatusText;
