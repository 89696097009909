import consumer from "./consumer";
import updateLobby from "../packs/lobby/updateLobby";
import sendPlayerToRound from "../packs/modals/sendPlayerToRound";

export default function connectLobby(lobbyCode) {
  const lobbyClient = consumer.subscriptions.create(
    { channel: "LobbiesChannel", lobby_access_code: lobbyCode },
    {
      received: (data) => {
        switch (data.action) {
          case "update":
            const readyUpButton = document.getElementById("ready-up-button");
            const unreadyButton = document.getElementById("unready-button");
            updateLobby(data.payload);
            readyUpButton.disabled = false;
            unreadyButton.disabled = false;
            break;
          case "sendPlayerToRound":
            sendPlayerToRound(data.payload);
            break;
          default:
            return;
        }
      },

      readyUp() {
        lobbyClient.perform("ready_up");
        const readyUpButton = document.getElementById("ready-up-button");
        readyUpButton.disabled = true;
      },

      unready() {
        lobbyClient.perform("unready");
        const unreadyButton = document.getElementById("unready-button");
        unreadyButton.disabled = true;
      },

      startRound() {
        const timeSelector = document.getElementById("time-selector");
        const locationsSelector = document.getElementById("locations-selector");
        lobbyClient.perform("start_round", {
          desired_location_set: locationsSelector.value,
          desired_round_length: timeSelector.value,
        });
      },
    }
  );
  return lobbyClient;
}
