import connectLobby from "../../channels/lobby_channel";
import connectRound from "../../channels/round_channel";
import parseUrl from "../utils/parseUrl";
import joinGame from "../modals/joinGame";

const connectSocket = () => {
  let socket;
  const { route, lobbyCode, subRoute, roundNumber } = parseUrl();
  if (route === "lobbies" && !subRoute) {
    if (socket) socket.unsubscribe();
    socket = connectLobby(lobbyCode);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  if (subRoute === "rounds") {
    if (socket) socket.unsubscribe();
    socket = connectRound(lobbyCode, roundNumber);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  if (subRoute === "join") {
    joinGame();
  }
  return socket;
};

export default connectSocket;
