import hostGame from "../modals/hostGame";
import joinGame from "../modals/joinGame";
import leaveRound from "../modals/leaveRound";
import endRound from "../modals/endRound";
import copyRoomCode from "../lobby/copyRoomCode";
import toggleRedX from "../round/toggleRedX";
import toggleShowLocation from "../round/toggleShowLocation";
import toggleMarkPlayer from "../round/toggleMarkPlayer";
import { socket } from "../application";
import giveFeedback from "../modals/giveFeedback";

// split by route

const addListeners = () => {
  const hostGameButton = document.getElementById("host-game-button");
  const joinGameButton = document.getElementById("join-game-button");
  const grHostGameButton = document.getElementById("gamerules-host-game-button");
  const grJoinGameButton = document.getElementById("gamerules-join-game-button");
  const roomCodeButton = document.querySelector(".copy-code-button");
  const readyUpButton = document.getElementById("ready-up-button");
  const unreadyButton = document.getElementById("unready-button");
  const startGameButton = document.getElementById("start-game-button");
  const leaveRoundButton = document.getElementById("leave-round-button");
  const endRoundButton = document.getElementById("end-round-button");
  const pauseButton = document.getElementById("pause");
  const resumeButton = document.getElementById("resume");
  const locationCards = document.querySelectorAll("#location-card");
  const playerBoxes = document.querySelectorAll(".player-box");
  const tapToHideText = document.getElementById("tap-to-hide-text");
  const polaroid = document.getElementById("polaroid");
  const giveFeedbackLink = document.getElementById("give-feedback");

  if (readyUpButton) readyUpButton.addEventListener("click", socket.readyUp);
  if (unreadyButton) unreadyButton.addEventListener("click", socket.unready);
  if (startGameButton) startGameButton.addEventListener("click", socket.startRound);
  if (hostGameButton) hostGameButton.addEventListener("click", hostGame);
  if (joinGameButton) joinGameButton.addEventListener("click", joinGame);
  if (grHostGameButton) grHostGameButton.addEventListener("click", hostGame);
  if (grJoinGameButton) grJoinGameButton.addEventListener("click", joinGame);
  if (roomCodeButton) roomCodeButton.addEventListener("click", copyRoomCode);
  if (leaveRoundButton) leaveRoundButton.addEventListener("click", leaveRound);
  if (endRoundButton) endRoundButton.addEventListener("click", endRound);
  if (pauseButton) pauseButton.addEventListener("click", socket.togglePaused);
  if (resumeButton) resumeButton.addEventListener("click", socket.togglePaused);
  if (locationCards)
    locationCards.forEach((card) => {
      card.addEventListener("click", toggleRedX);
    });
  if (polaroid) polaroid.addEventListener("click", toggleShowLocation);
  if (tapToHideText) tapToHideText.addEventListener("click", toggleShowLocation);
  if (playerBoxes)
    playerBoxes.forEach((box) => {
      box.addEventListener("click", toggleMarkPlayer);
    });
  if (giveFeedbackLink) giveFeedbackLink.addEventListener("click", giveFeedback);
};

export default addListeners;
