import makePlayerRow from "./makePlayerRow";
import { socket } from "../application";

const makePlayerRows = ({ players, counts, currentUser }) => {
  // Add players
  const newList = document.createElement("div");
  newList.classList.add("players");
  newList.id = "active-users";
  Object.keys(players).forEach((playerId) => {
    const player = players[playerId];
    const position = player.isLeader ? "afterbegin" : "beforeend";
    newList.insertAdjacentHTML(position, makePlayerRow(player));
  });
  // Add blank rows
  let blankCount = 9 - counts.players;
  for (var i = 0; i < blankCount; i++) {
    newList.insertAdjacentHTML("beforeend", makePlayerRow({ blank: true }));
  }
  // Add listener for ready/unready button on player row
  const currentUserReadyBox = newList.querySelector(`#p${currentUser.id}`);
  currentUserReadyBox.classList.add("current-user-status-box");
  if (currentUser.isReady) {
    currentUserReadyBox.addEventListener("click", socket.unready);
  } else {
    currentUserReadyBox.addEventListener("click", socket.readyUp);
  }
  // Touch DOM
  const list = document.getElementById("active-users");
  list.replaceWith(newList);
};

export default makePlayerRows;
