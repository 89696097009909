import dayjs from "dayjs";
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
import roundOver from "../modals/roundOver";

const timer = document.getElementById("countdown");
const stickyTimer = document.getElementById("sticky-note-countdown");
let roundOverModalNotShown = true;

export const updateTimers = () => {
  const timeLeft = calcTimeLeft(timer);
  if (timeLeft.seconds() < 0) {
    timer.classList.add("timer-over");
    if (roundOverModalNotShown) {
      roundOverModalNotShown = false;
      return roundOver();
    } else {
      return;
    }
  }
  if (timeLeft.seconds() === 59) updateStickyText();
  timer.innerHTML = timeLeft.format("m:ss");
  stickyTimer.innerHTML = dayjs
    .duration({ minutes: timeLeft.minutes() % 2, seconds: timeLeft.seconds() })
    .format("m:ss");
};

const calcTimeLeft = (timer) => {
  const { paused, endTime, pausedTimestamp } = timer.dataset;
  if (paused === "true") {
    const pausedTime = dayjs.unix(endTime - pausedTimestamp);
    return pausedTime;
  } else {
    return dayjs.duration(dayjs.unix(endTime).diff(dayjs()));
  }
};

export const startTimers = () => {
  setInterval(updateTimers, 1000);
};

export const updateStickyText = () => {
  let index;
  const timer = document.getElementById("countdown");
  const timeLeft = calcTimeLeft(timer);
  if (timeLeft.minutes() < 2) {
    index = 4;
    const nextSuggestionIn = document.getElementById("next-suggestion-countdown");
    nextSuggestionIn.innerHTML = `<em>No more suggestions for this round</em>`;
    stickyTimer.style.display = "none";
  }
  if (timeLeft.minutes() >= 2) index = 3;
  if (timeLeft.minutes() >= 4) index = 2;
  if (timeLeft.minutes() >= 6) index = 1;
  if (timeLeft.minutes() >= 8) index = 0;

  const stickyTexts = document.querySelectorAll(".sticky-text");
  stickyTexts.forEach((elem) => {
    elem.id === index.toString() ? (elem.style.display = "") : (elem.style.display = "none");
  });
};
