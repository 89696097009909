import swal from "sweetalert";
import { askName, askNameSpecifically, askLobby, notValidInviteCode } from "./askForInput";
import parseUrl from "../utils/parseUrl";
import removeModal from "./removeModal";
import getCurrentUser from "../utils/getCurrentUser";

export default function joinSwal() {
  const userSignedIn = getCurrentUser() !== null;
  const { lobbyCode } = parseUrl();
  removeModal();
  if (userSignedIn) {
    if (lobbyCode) return (window.location = `/lobbies/${lobbyCode}`);
    askLobbyThenRedirect();
  } else {
    askName()
      .then((name) => {
        if (!name) throw null;
        // removeModal();
        Rails.ajax({
          url: "/lobby_exists",
          type: "post",
          data: `access_code=${encodeURIComponent(name)}`,
          success: function (data) {
            const enteredName = data.enteredName;
            if (data.lobbyExists) {
              askNameSpecifically()
                .then((value) => {
                  if (!value) throw null;
                  // removeModal();
                  Rails.ajax({
                    url: "/create_user",
                    type: "post",
                    data: `name=${encodeURIComponent(value)}`,
                    success: function () {
                      window.location = `/lobbies/${enteredName.toUpperCase()}`;
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                  swal.stopLoading();
                  swal.close();
                });
            } else {
              Rails.ajax({
                url: "/create_user",
                type: "post",
                data: `name=${encodeURIComponent(enteredName)}`,
                success: function () {
                  if (lobbyCode) return (window.location = `/lobbies/${lobbyCode}`);
                  askLobbyThenRedirect();
                },
              });
            }
          },
        });
      })
      .catch((err) => {
        console.log(err);
        swal.stopLoading();
        swal.close();
      });
  }
}

const askLobbyThenRedirect = () => {
  return askLobby()
    .then((value) => {
      if (!value) throw null;
      Rails.ajax({
        url: "/lobby_exists",
        type: "post",
        data: `access_code=${encodeURIComponent(value)}`,
        success: function (data) {
          if (data.lobbyExists) {
            window.location = `/lobbies/${data.enteredName.toUpperCase()}`;
          } else {
            notValidInviteCode(data.enteredName.toUpperCase());
          }
        },
      });
    })
    .catch(() => {
      swal.stopLoading();
      swal.close();
    });
};
