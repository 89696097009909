const updateButtons = ({ currentUser, allReady }) => {
  const readyUpButton = document.getElementById("ready-up-button");
  const unreadyButton = document.getElementById("unready-button");

  const updateReadyButtons = () => {
    if (currentUser.isReady) {
      if (readyUpButton.style.display === "none") return;
      readyUpButton.style = "display: none;";
      unreadyButton.style = "";
    } else {
      if (readyUpButton.style.display === "") return;
      readyUpButton.disabled = false;
      readyUpButton.style = "";
      unreadyButton.style = "display: none;";
    }
  };

  const updateStartButton = () => {
    const startGameButton = document.getElementById("start-game-button");
    const startGameButtonTooltip = document.getElementById("start-game-tooltip");
    if (allReady) {
      startGameButton.disabled = false;
      startGameButtonTooltip.dataset.originalTitle = "";
    } else {
      if (startGameButton.disabled) return;
      startGameButton.disabled = true;
      startGameButtonTooltip.dataset.originalTitle = "All players not ready";
    }
  };

  updateReadyButtons();
  if (currentUser.isLeader) updateStartButton();
};

export default updateButtons;
