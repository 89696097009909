export default function copyRoomCode() {
  var dummy = document.createElement("input"),
    copyIcon = document.querySelector("#copy-icon"),
    roomCodeHeadline = document.querySelector(".copy-code-button"),
    text = window.location.href;
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  roomCodeHeadline.classList.remove("animate");
  roomCodeHeadline.classList.add("animate");
  copyIcon.className = "fas fa-check";
  setTimeout(function () {
    roomCodeHeadline.classList.remove("animate");
    if ((copyIcon.className = "fas fa-check"))
      copyIcon.className = "far fa-copy";
  }, 1500);
}
