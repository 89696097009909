import swal from "sweetalert";
import spyIcon from "../../../assets/images/icons/spygrey.png";
import lockIcon from "../../../assets/images/icons/lockgrey.png";

export const askLobby = () => {
  return swal({
    title: "Enter invite code",
    content: {
      element: "input",
      attributes: {
        placeholder: "AX1N9",
        type: "text",
        style: "text-transform: uppercase",
        maxLength: 5,
      },
    },
    icon: lockIcon,
    buttons: {
      cancel: { text: "CANCEL", value: null, closeModal: true },
      confirm: { text: "JOIN", value: true, closeModal: false },
    },
  });
};

export function askName() {
  return swal({
    title: "What's your name, detective?",
    content: {
      element: "input",
      attributes: {
        placeholder: "Sherlock",
        type: "text",
        maxLength: 10,
      },
    },
    icon: spyIcon,
    buttons: {
      cancel: { text: "CANCEL", value: null, closeModal: true },
      confirm: { text: "LET'S GO", value: true, closeModal: false },
    },
    closeOnConfirm: false,
  });
}

export function askNameSpecifically() {
  return swal({
    title: "You entered an invite code. Now, what's your name?",
    content: {
      element: "input",
      attributes: {
        placeholder: "Sherlock",
        type: "text",
        maxLength: 10,
      },
    },
    icon: spyIcon,
    buttons: {
      cancel: { text: "CANCEL", value: null, closeModal: true },
      confirm: { text: "LET'S GO", value: true, closeModal: false },
    },
  });
}

export const notValidInviteCode = (codeEntered) => {
  return swal({
    title: "Invalid Code",
    text: `"${codeEntered}" is not a valid invite code`,
    icon: lockIcon,
    button: "OK",
  });
};

export const redirectIfLobbyExists = (lobbyCode) => {
  if (!lobbyCode) throw null;
  Rails.ajax({
    url: "/lobby_exists",
    type: "post",
    data: `access_code=${encodeURIComponent(lobbyCode)}`,
    success: function (data) {
      if (data.lobbyExists) {
        window.location.replace(`/lobbies/${data.enteredName.toUpperCase()}`);
      } else {
        notValidInviteCode(data.enteredName);
      }
    },
  });
};
