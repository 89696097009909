import swal from "sweetalert";
import { socket } from "../application";
import bombIcon from "../../../assets/images/icons/bomb.png";

const endRound = async () => {
  try {
    const willEnd = await swal({
      title: "End round?",
      buttons: ["CANCEL", "YES"],
      icon: bombIcon,
    });

    if (willEnd) socket.endRound();
  } catch (error) {
    console.log(error);
  }
};

export default endRound;
