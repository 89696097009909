import feedbackBubbleIcon from "../../../assets/images/icons/feedbackbubble.png";
import thankYouIcon from "../../../assets/images/icons/thankyou.png";

const giveFeedback = () => {
  swal({
    title: "Send us your feedback",
    // className: "feedback-swal",
    icon: feedbackBubbleIcon,
    content: {
      element: "input",
      attributes: {
        placeholder: "Your thoughts here",
        type: "text",
      },
    },
    buttons: ["CANCEL", "SUBMIT"],
  }).then((value) => {
    if (value)
      Rails.ajax({
        url: "/give_feedback",
        type: "post",
        data: `feedback=${value}`,
        success: function (data) {
          swal({
            title: "Feedback received",
            text: "Thank you!",
            icon: thankYouIcon,
          });
        },
      });
  });
};

export default giveFeedback;
