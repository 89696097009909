const toggleShowLocation = () => {
  const roleContainer = document.querySelector(".role-container");
  const tapToHideText = document.getElementById("tap-to-hide-text");
  const polaroid = document.getElementById("polaroid");
  const polaroidImage = document.getElementById("round-location-img");
  const polaroidImageHidden = document.getElementById(
    "round-location-img-hidden"
  );
  if (polaroid.classList.contains("visible")) {
    polaroidImage.style.display = "none";
    polaroidImageHidden.style.display = "";
    polaroid.setAttribute("polaroid-caption", "(Hidden)");
    polaroid.classList.toggle("visible");
    tapToHideText.innerHTML = "<em>Tap to reveal</em>";
    roleContainer.style.display = "none";
  } else {
    polaroidImage.style.display = "";
    polaroidImageHidden.style.display = "none";
    polaroid.setAttribute("polaroid-caption", polaroid.dataset.caption);
    polaroid.classList.toggle("visible");
    tapToHideText.innerHTML = "<em>Tap to hide</em>";
    roleContainer.style.display = "";
  }
};

export default toggleShowLocation;
