import makePlayerRows from "./makePlayerRows";
import updateStatusText from "./updateStatusText";
import getCurrentUser from "../utils/getCurrentUser";
import updateButtons from "./updateButtons";

const updateLobby = (previousState) => {
  const lobbyState = addCurrentUserToState(previousState);
  updateStatusText(lobbyState);
  makePlayerRows(lobbyState);
  updateButtons(lobbyState);
};

const addCurrentUserToState = (previousState) => {
  const currentUser = getCurrentUser();
  const lobbyState = {
    ...previousState,
    currentUser: previousState.players[currentUser],
  };
  return lobbyState;
};

export default updateLobby;
