const makePlayerRow = ({ id, name, isReady, isLeader, blank }) => {
  if (blank) {
    return `<div class="player-envelope-line blank"></div>`;
  } else {
    return `<div class="player-envelope-line${
      isReady ? "-ready" : ""
    }"><div class="player-name"><i class="${
      isLeader ? "fas fa-user-tie" : "fas fa-user"
    }"></i>${" " + name}</div><div class="ready-status${
      isReady ? "" : "-not"
    }-ready" id="p${id}">${isReady ? "READY" : "NOT READY"}</div></div>`;
  }
};

export default makePlayerRow;

// return `<div class="player-envelope-line blank"></div>`;
// } else {
//   return `<div class="player-envelope-line${
//     isReady ? "-ready" : ""
//   }"><div class="player-name"><img src="/assets/avatar3.png" class="player-row-avatar">${
//     " " + name
//   }</div><div class="ready-status${
//     isReady ? "" : "-not"
//   }-ready" id="p${id}">${isReady ? "READY" : "NOT READY"}</div></div>`;
// }
