import swal from "sweetalert";
import parseUrl from "../utils/parseUrl";
import exitIcon from "../../../assets/images/icons/exitround.png";

const leaveRound = async () => {
  const { lobbyCode } = parseUrl();
  // const swalOpen = document.getElementsByClassName("swal-modal");
  // if (swalOpen.length > 0) return;
  try {
    const willLeave = await swal({
      title: "Leave this round?",
      buttons: ["CANCEL", "YES"],
      icon: exitIcon,
    });
    console.log(willLeave);

    if (willLeave) {
      window.location = `/lobbies/${lobbyCode}`;
    }
  } catch (error) {
    console.log(error);
  }
};

export default leaveRound;
