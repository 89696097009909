import consumer from "./consumer";
import updateRound from "../packs/round/updateRound";
import { startTimers } from "../packs/timer/timer";

export default function connectRound(lobbyCode, roundNumber) {
  const roundClient = consumer.subscriptions.create(
    {
      channel: "RoundsChannel",
      lobby_access_code: lobbyCode,
      round_number: roundNumber,
    },
    {
      connected() {
        startTimers();
      },

      received: (data) => {
        switch (data.action) {
          case "update":
            updateRound(data.payload);
            break;
          default:
            return;
        }
      },

      togglePaused() {
        roundClient.perform("toggle_paused");
      },

      endRound() {
        roundClient.perform("end_round");
      },
    }
  );
  return roundClient;
}
