import swal from "sweetalert";
import parseUrl from "../utils/parseUrl";
import stopwatchIcon from "../../../assets/images/icons/stopwatch.png";

const roundOver = async () => {
  const { lobbyCode } = parseUrl();
  try {
    const willLeave = await swal({
      title: "Round over!",
      button: "LEAVE ROUND",
      icon: stopwatchIcon,
    });

    if (willLeave) {
      window.location.replace(`/lobbies/${lobbyCode}`);
    }

    // swap endRound button for leaveRound, only matters for leader
    const endRoundButton = document.getElementById("end-round-button");
    const leaveRoundButton = document.getElementById("leave-round-button");
    endRoundButton.style.display = "none";
    leaveRoundButton.style.display = "";
  } catch (error) {
    console.log(error);
  }
};

export default roundOver;
