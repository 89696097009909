import swal from "sweetalert";
import { askName } from "./askForInput";
import removeModal from "./removeModal";

export default function hostGame(clickEvent) {
  const userSignedIn = clickEvent.target.dataset.userSignedIn === "true";
  removeModal();
  if (userSignedIn) {
    return Rails.ajax({
      url: "/create_lobby",
      type: "post",
      success: function (data) {
        window.location.href = `/lobbies/${data.access_code}`;
      },
    });
  } else {
    askName()
      .then((value) => {
        if (!value) throw null;
        Rails.ajax({
          url: "/create_user",
          type: "post",
          data: `name=${encodeURIComponent(value)}`,
          success: function (userData) {
            Rails.ajax({
              url: "/create_lobby",
              type: "post",
              data: `user_id=${userData.user_id}`,
              success: function (data) {
                window.location.href = `/lobbies/${data.access_code}`;
              },
            });
          },
        });
      })
      .catch((err) => {
        swal.stopLoading();
        swal.close();
      });
  }
}
