const getCurrentUser = () => {
  const cookies = document.cookie;
  let currentUser = null;
  cookies.split(";").forEach((cookie) => {
    const split = cookie.trim().split("=");
    const key = split[0];
    const value = split[1];
    if (key === "currentUser") currentUser = value;
  });
  return currentUser;
};

export default getCurrentUser;
