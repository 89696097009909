import swal from "sweetalert";
const spyURL = "/assets/icons/spygrey.png";

const sendPlayerToRound = ({ lobbyCode, roundNumber }) => {
  const content = document.createElement("div");
  content.classList.add("loader");
  swal({
    title: "Starting round",
    text: "Choosing spy...",
    buttons: false,
    timer: 4000,
    content: {
      element: "div",
      attributes: {
        classList: "loader",
      },
    },
  });

  const changeText = function () {
    const text = document.getElementsByClassName("swal-text")[0];
    setTimeout(() => {
      text.innerHTML = "Choosing location...";
    }, 1500);
    setTimeout(() => {
      text.innerHTML = "Redirecting...";
    }, 3000);
  };
  const redirect = function () {
    window.location = `/lobbies/${lobbyCode}/rounds/${roundNumber}`;
  };

  changeText();
  setTimeout(redirect, 3500);
};

export default sendPlayerToRound;
